import { Box } from "@mui/material";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../auth/provider";
import Login from "./Auth/login";
import Navbar from "./Navbar";

const Website = () => {
  const user = useContext(AuthContext);

  if (user.getUserCookie() === undefined) {
    return <Login />;
  }

  return (
    <Box sx={{ fontFamily: "Cairo" }}>
      <Navbar />
      <Outlet />
    </Box>
  );
};

export default Website;
