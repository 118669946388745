import React from "react";
import { Modal, Box } from "@mui/material";

const ProductModal = ({ open, handleClose, children }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    minWidth: 400,
    bgcolor: "background.paper",
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#707070",
      borderRadius: "6px",
      padding: "2px",
      visibility: "hidden",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        visibility: "visible",
      },
    },
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default ProductModal;
