import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import { useRecoilValue } from "recoil";
import { localeState } from "../../atoms/localeState";
import Iconify from "../Iconify";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "10px",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
}));

const StyledIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-disabled": {
    color: "rgba(145, 158, 171, 0.8);",
  },
  fontSize: "26px",
  margin: "0 5px 0 ",
}));

const ReactTable = ({ data, columns, meta }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const lang = useRecoilValue(localeState);

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    headerGroups,
    prepareRow,
    nextPage,
    previousPage,
    state,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: meta?.page ?? 1 },
    },
    usePagination
  );

  const canNextPage = () => {
    return state.pageIndex < meta?.pageCount;
  };

  const canPreviousPage = () => {
    return state.pageIndex > 1;
  };

  React.useEffect(() => {
    if (meta) {
      setSearchParams({ page: state.pageIndex });
    }
  }, [state.pageIndex]);

  return (
    <>
      <TableContainer sx={{ borderRadius: "10px" }}>
        <Table
          {...getTableProps()}
          sx={{ backgroundColor: "background.default" }}
        >
          <TableHead
            sx={{
              backgroundColor: "primary.main",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    sx={{
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    {<FormattedMessage id={column.render("Header")} />}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  sx={{
                    "&:nth-child(odd)": {
                      backgroundColor: "white",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                      borderColor: "background.default",
                    },
                  }}
                >
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {meta && (
          <RootStyle>
            <StyledIcon disabled={!canPreviousPage()} onClick={previousPage}>
              <Iconify
                icon={
                  lang === "en"
                    ? "material-symbols:navigate-before"
                    : "material-symbols:navigate-next"
                }
              />
            </StyledIcon>
            <StyledIcon disabled={!canNextPage()} onClick={nextPage}>
              <Iconify
                icon={
                  lang === "en"
                    ? "material-symbols:navigate-next"
                    : "material-symbols:navigate-before"
                }
              />
            </StyledIcon>
          </RootStyle>
        )}
      </TableContainer>
    </>
  );
};

export default ReactTable;
