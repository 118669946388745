import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../auth/provider";

export const useAxios = () => {
  const user = useContext(AuthContext);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVER,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: user && `Bearer ${user?.user?.token}`,
    },
  });

  return axiosInstance;
};
