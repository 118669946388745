import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/form/CustomInput";
import { Button, Stack } from "@mui/material";
import Asynchronous from "../../../components/form/Async";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";

const ProductForm = ({ handleClose, setValue, getValues }) => {
  const [customPrice, setCustomPrice] = React.useState(false);
  const productSchema = Yup.object().shape({
    product: Yup.object()
      .typeError("يرجى اختيار منتج")
      .required("يرجى اختيار منتج"),
    amount: Yup.number()
      .typeError("يرجى تحديد الكمية")
      .required("يرجى تحديد كمية"),
    custom_price: Yup.number().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      product: "",
      amount: "",
      custom_price: null,
    },
  });
  const onSubmitForm = (data) => {
    let products = getValues("products");
    setValue("products", [...products, data]);
    handleClose();
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={2}>
        <Asynchronous
          name="product"
          label="product"
          url="products?populate=*"
          optionLabel="name"
          control={control}
          errors={errors}
          getLabel={(product) => {
            return product?.brand?.data?.attributes?.name
              ? product?.brand?.data?.attributes?.name + "-" + product?.name
              : product?.name;
          }}
        />

        <CustomInput
          name="amount"
          label="amount"
          control={control}
          errors={errors}
        />
        {!customPrice && (
          <Button
            onClick={() => setCustomPrice((prev) => !prev)}
            sx={{ fontWeight: "700" }}
          >
            <FormattedMessage id="add_custom_price" />
          </Button>
        )}
        {customPrice && (
          <CustomInput
            name="custom_price"
            label="custom_price"
            type="number"
            control={control}
            errors={errors}
          />
        )}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Button variant="contained" type="submit">
            <FormattedMessage id="add" />
          </Button>
          <Button variant="contained" onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ProductForm;
