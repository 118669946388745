import { createBrowserRouter, Navigate } from "react-router-dom";
import Invoice from "./screens/Invoice";
import CreateInvoice from "./screens/Invoice/CreateInvoice";
import ShowInvoice from "./screens/Invoice/Show";
import Products from "./screens/Products";
import Website from "./screens/Website";
import ShowProduct from "./screens/Products/Show";

const useRouter = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <Website />,
      // errorElement: <ErrorPage />,
      children: [
        {
          element: <Products />,
          index: true,
        },
        {
          path: "products/:id",
          element: <ShowProduct />,
        },
        {
          path: "create-invoice",
          element: <CreateInvoice />,
        },
        {
          path: "my-invoices",
          children: [
            {
              element: <Invoice />,
              index: true,
            },
            {
              path: ":id",
              element: <ShowInvoice />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
};

export default useRouter;
