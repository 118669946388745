import { Container, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { useAxios } from "../../../api";
import { localeState } from "../../../atoms/localeState";
import Spinner from "../../../components/Spinner";
import ReactMarkdown from "react-markdown";
//
const StyledContainer = styled(Container)({
  display: "flex",
  marginBottom: "20px",
  gap: "30px",
  boxSizing: "border-box",
});

const ShowProduct = () => {
  const { id } = useParams();
  const axiosInstance = useAxios();
  const lang = useRecoilValue(localeState);

  const { data, isLoading } = useQuery([`product_${id}`], () =>
    axiosInstance
      .get(`/products/${id}`, {
        params: {
          populate: "*",
        },
      })
      .then((data) => data.data.data.attributes)
  );

  const { data: cost } = useQuery("cost", () =>
    axiosInstance.get("/cost").then((data) => {
      return data.data.data.attributes.variableCost;
    })
  );

  if (isLoading && !data) {
    return <Spinner />;
  }

  return (
    <StyledContainer>
      <Box width="50%" height="50vh">
        <Box
          component="img"
          src={
            data.image?.data?.attributes.url
              ? `${process.env.REACT_APP_BACKEND_SERVER_upload}${data.image?.data?.attributes.url}`
              : "https://www.mrpanet.org/global_graphics/default-store-350x350.jpg"
          }
          width="100%"
          heigtht="100%"
        />
      </Box>
      <Box
        sx={{
          direction: lang === "en" ? "rtl" : "ltr",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "50%",
        }}
      >
        <Typography variant="h4" borderBottom="2px solid" width="fit-content">
          {data.name} {data.brand.data.attributes.name}
        </Typography>
        <Box sx={{ fontWeight: "700", fontSize: "18px" }}>
          {data.description}
        </Box>

        <Box border="1px solid" borderRadius="20px" p={2}>
          <ReactMarkdown>{data.details ?? "لا يوجد معلومات"}</ReactMarkdown>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignSelf: "flex-end",
            direction: lang === "en" && "ltr",
          }}
        >
          <Box sx={{ fontWeight: "700" }}>
            <FormattedMessage id="quantity" />
          </Box>

          <Box>{data.quantity}</Box>
        </Box>
        <Box sx={{ marginTop: "auto", direction: lang === "en" && "ltr" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
              borderBottom: "1px solid",
            }}
          >
            <Box sx={{ fontWeight: "700" }}>
              <FormattedMessage id="unit_price" />
            </Box>

            <Box>{data.price_one * cost}</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
              borderBottom: "1px solid",
            }}
          >
            <Box sx={{ fontWeight: "700" }}>
              <FormattedMessage id="half_wholesale_price" />
            </Box>

            <Box>{data.price_half_many * cost}</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
              borderBottom: "1px solid",
            }}
          >
            <Box sx={{ fontWeight: "700" }}>
              <FormattedMessage id="wholesale_price" />
            </Box>

            <Box>{data.price_many * cost}</Box>
          </Box>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default ShowProduct;
