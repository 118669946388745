import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

export default function Dropdown({
  label,
  name,
  width,
  optionUrl,
  control,
  optionLabel,
  errors,
  options,
  handleChange,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        let { onChange, value } = props.field;

        return (
          <FormControl error={errors[name]}>
            <InputLabel id="demo-simple-select-disabled-label">
              <FormattedMessage id={label} />
            </InputLabel>
            <Select
              id="demo-simple-select"
              value={value}
              label={<FormattedMessage id={label} />}
              onChange={(event, values, reason) => {
                handleChange
                  ? handleChange(values)
                  : onChange(event, values, reason);
              }}
              helperText={
                errors[name] && <FormattedMessage id={errors[name]?.message} />
              }
            >
              {options.map((option) => {
                if (option[optionLabel])
                  return (
                    <MenuItem key={option.id} value={option.value}>
                      {option[optionLabel]}
                    </MenuItem>
                  );
                return <MenuItem value={option}>{option}</MenuItem>;
              })}
            </Select>
            {errors[name] && (
              <FormHelperText>
                <FormattedMessage id={errors[name]?.message} />
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
