import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";
import { AuthProvider } from "./auth/provider";
import { I18nProvider } from "./i18n/I18nProvider";
import useRouter from "./routes";
import { useTheme } from "./theme";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { useRecoilValue } from "recoil";
import { localeState } from "./atoms/localeState";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const router = useRouter();
  const theme = useTheme();
  const lang = useRecoilValue(localeState);

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider>
        <AuthProvider>
          {lang === "ar" && (
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </CacheProvider>
          )}
          {lang === "en" && (
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          )}
        </AuthProvider>
      </I18nProvider>
    </QueryClientProvider>
  );
}

export default App;
