import { LoadingButton } from '@mui/lab';
import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { format, parseISO } from 'date-fns';
import fileDownload from 'js-file-download';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useAxios } from '../../../api';
import Spinner from '../../../components/Spinner';
import { numberWithCommas } from '../../../functions';
import logo from '../../../logo.jpg';

const NotesContainer = styled(Stack)(({ theme }) => ({
 border: '1px solid',
 borderColor: theme.palette.primary.main,
 padding: '2rem',
 borderRadius: '10px',
 width: '90%',
}));

const ShowInvoice = () => {
 const { id } = useParams();
 let totalPrice = 0;
 const axiosInstance = useAxios();

 const { data, isLoading } = useQuery(
  `invoice_${id}`,
  () =>
   axiosInstance
    .get(`/invoices/${id}`, {
     params: {
      populate: '*',
     },
    })
    .then((data) => {
     return data.data.data.attributes;
    }),
  {
   enabled: id !== undefined,
  }
 );

 const { isLoading: isDownLoading, refetch } = useQuery(
  `invoice/${id}/generate-pdf`,
  () =>
   axiosInstance
    .get(`invoice/${id}/generate-pdf`, {
     responseType: 'blob',
    })
    .then(async (response) => {
     fileDownload(response.data, `invoice-${id}.pdf`);
    }),

  {
   enabled: false,
  }
 );

 if (isLoading && !data) {
  return <Spinner />;
 }

 return (
  <Container>
   <Box
    sx={{
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'space-between',
     alignItems: 'center',
     borderRadius: '10px',
     backgroundColor: 'background.default',
     padding: '30px',
     gap: '25px',
    }}
   >
    <Box
     sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
     }}
    >
     <Box
      sx={{
       display: 'flex',
       flexDirection: 'column',
       gap: '10px',
       width: '30%',
      }}
     >
      <Box
       sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
       }}
      >
       <Box sx={{ fontWeight: '700' }}>
        <FormattedMessage id="invoice_date" />:
       </Box>
       <Box>{format(parseISO(data?.createdAt), 'dd/MM/yyyy')}</Box>
      </Box>
      {data?.engineer && (
       <Box
        sx={{
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
        }}
       >
        <Box sx={{ fontWeight: '700' }}>
         <FormattedMessage id="engineer" />:
        </Box>
        <Box>{data?.engineer?.data?.attributes?.first_name + ' ' + data?.engineer?.data?.attributes?.last_name}</Box>
       </Box>
      )}
      {data?.type_group && (
       <Box
        sx={{
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
        }}
       >
        <Box sx={{ fontWeight: '700' }}>
         <FormattedMessage id="type_group" />:
        </Box>
        <Box>{data?.type_group}</Box>
       </Box>
      )}
     </Box>
     <Box>
      <Box height={200} component="img" src={logo} sx={{ mixBlendMode: 'multiply' }} />
     </Box>
    </Box>
    {data?.description && (
     <Box
      sx={{
       display: 'flex',
       justifyContent: 'space-between',
       alignItems: 'center',
      }}
     >
      <Box sx={{ fontWeight: '700', paddingInlineEnd: '10px' }}>
       <FormattedMessage id="description" />:
      </Box>
      <Box>{data?.description}</Box>
     </Box>
    )}
    <Box
     sx={{
      borderTop: '2px solid',
      borderBottom: '2px solid',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '700',
      fontSize: '26px',
      padding: '10px 0',
     }}
    >
     <FormattedMessage id="summary" />
    </Box>
    <TableContainer>
     <Table>
      <TableHead sx={{ '& > tr > th': { fontWeight: '600' } }}>
       <TableRow>
        <TableCell>
         <FormattedMessage id="product" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="amount" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="one_price" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="total_price" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {data?.order?.length > 0 &&
        data?.order.map((order) => {
         let price = Number(order.unit_price) * Number(order.quantity);
         totalPrice += price;
         return (
          <TableRow key={order.id}>
           <TableCell>{order.name}</TableCell>
           <TableCell>{order.quantity ?? 1}</TableCell>
           <TableCell>{numberWithCommas(order.unit_price) ?? 0}</TableCell>
           <TableCell>{numberWithCommas(price)}</TableCell>
          </TableRow>
         );
        })}
      </TableBody>
     </Table>
     <Box
      sx={{
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       fontWeight: '600',
       padding: '20px 0 0 0',
      }}
     >
      <Box
       sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
       }}
      >
       <FormattedMessage id="all_price" />
       <Box fontWeight={700}>{numberWithCommas(totalPrice)}</Box>
      </Box>
     </Box>

     {data?.discount > 0 && (
      <>
       <Box
        sx={{
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         fontWeight: '600',
         padding: '20px 0 0 0',
        }}
       >
        <Box
         sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
         }}
        >
         <FormattedMessage id="discount" />
         <Box fontWeight={700}>{numberWithCommas(data?.discount ?? 0)}</Box>
        </Box>
       </Box>
       <Box
        sx={{
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         fontWeight: '600',
         padding: '20px 0 0 0',
        }}
       >
        <Box
         sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
         }}
        >
         <FormattedMessage id="total_price_after_discount" />
         <Box fontWeight={700}>{numberWithCommas(totalPrice - (data?.discount ?? 0))}</Box>
        </Box>
       </Box>
      </>
     )}
    </TableContainer>
    {data?.notes?.data?.length > 0 && (
     <NotesContainer>
      {data.notes.data.map((note) => {
       return note.attributes?.nots;
      })}
     </NotesContainer>
    )}
    <LoadingButton type="button" onClick={refetch} loading={isDownLoading}>
     <FormattedMessage id="print_invoice" />
    </LoadingButton>
   </Box>
  </Container>
 );
};

export default ShowInvoice;
