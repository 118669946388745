import React from 'react';
import CustomCheckbox from '../../../components/form/CustomCheckbox/index';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import Asynchronous from '../../../components/form/Async';
import fileDownload from 'js-file-download';
import {useQuery} from 'react-query';
import {useAxios} from '../../../api';
import {Button , Stack} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {LoadingButton} from '@mui/lab';

const PrintProducts = ({handleClose}) => {
  const axiosInstance = useAxios();
  let pricesDetails = [];

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categories: [],
      price_one: false,
      price_many: false,
      price_half_many: false,
    },
  });
  const { isLoading, refetch } = useQuery(
    `product/list_price`,
    () =>
      axiosInstance
        .get(`product/list_price`, {
          params:{
            pagination:{
              limit:-1
            },
            filters:{
              category: getValues('categories')?.length > 0 ? getValues('categories')?.map(category=>category?.id):[],
            },
            display_prices:pricesDetails?.map(price=>price)
          },
          responseType: "blob",
        })
        .then(async (response) => {
          fileDownload(response.data, `invoice.pdf`);
        }),

    {
      enabled: false,
    }
  );

  function onSubmitForm (data){
    if(data.price_one) pricesDetails.push('price_one')
    if(data.price_half_many) pricesDetails.push('price_half_many')
    if(data.price_many) pricesDetails.push('price_many')
    refetch()
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Asynchronous
          name="categories"
          url="categories"
          optionLabel="name"
          control={control}
          errors={errors}
          label="categories"
          multiple={true}
        />
        <Stack>
          <CustomCheckbox 
            name="price_one"
            label="unit_price"
            control={control}
            errors={errors}
            />

          <CustomCheckbox 
            name="price_many"
            label="wholesale_price"
            control={control}
            errors={errors}
            />

          <CustomCheckbox 
            name="price_half_many"
            label="half_wholesale_price"
            control={control}
            errors={errors}
          />
        </Stack>
        <LoadingButton type="submit" loading={isLoading}>
          <FormattedMessage id="add" />
        </LoadingButton>
        <Button onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

      </form>
    </div>
  );
}

export default PrintProducts;
