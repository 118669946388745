import { createTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { localeState } from "../atoms/localeState";

export const useTheme = () => {
  const lang = useRecoilValue(localeState);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#076497",
      },
      background: {
        default: "#EDECEE",
      },
      secondary: {
        main: "#84C33E",
        light: "#84c33e66",
      },
    },
    direction: lang === "en" ? "ltr" : "rtl",
  });
  return theme;
};
