import { Button, Container, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useAxios } from "../../api";
import ReactTable from "../../components/ReactTable";
import Spinner from "../../components/Spinner";
import { useSearchParams } from "react-router-dom";
import ImageModal from "./ImageModal";
import Asynchronous from "../../components/form/Async";
import { useForm } from "react-hook-form";
import Iconify from "../../components/Iconify";
import PrintProducts from "./print";
import Modal from '../../components/Modal/index';
import { numberWithCommas } from '../../functions';

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: "inherite",
  color: theme.palette.primary.main,
  fontWeight: "600",
  whiteSpace: "nowrap",
  textDecoration: "none",
  fontSize: "14px",
  textTransform: "uppercase",
  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: "inherit",
  },
  transition: "300ms",
  padding: "5px",
}));

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  gap: "20px",
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "30px",
  letterSpacing: "-0.1rem",
  color: theme.palette.primary.main,
}));

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "600",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Products = () => {
  const axiosInstance = useAxios();
  let [searchParams, setSearchParams] = useSearchParams();

  const [openProductModal, setOpenProductModal] = React.useState(false);
  const handlCloseProductModal = () => setOpenProductModal(false);
  const handlOpenProductModal = () => setOpenProductModal(true);
  
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categories: [],
    },
  });

  const fetchedData = {
    page: searchParams.get("page") ?? undefined,
    categories: watch("categories").map((category) => category.id),
  };

  const { data, isLoading } = useQuery(["products", fetchedData], () =>
    axiosInstance
      .get("/products", {
        params: {
          populate: "*",
          pagination: {
            page: fetchedData.page,
          },
          sort: {
            id: "desc",
          },
          filters: {
            category: fetchedData.categories,
          },
        },
      })
      .then((data) => ({
        data: data.data.data.map((d) => ({ ...d.attributes, id: d.id })),
        meta: data.data.meta.pagination,
      }))
  );

  const { data: cost } = useQuery("cost", () =>
    axiosInstance.get("/cost").then((data) => {
      return data.data.data.attributes.variableCost;
    })
  );

  const columns = [
    {
      Header: "product_image",
      Cell: ({ row }) => {
        return <ImageModal row={row} />;
      },
    },
    {
      Header: "product_name",
      accessor: "name",
    },
    {
      Header: "category",
      accessor: "category.data.attributes.name",
    },
    {
      Header: "brand",
      accessor: "brand.data.attributes.name",
    },
    {
      Header: "description",
      accessor: "Description",
      Cell: ({ row }) => (
        <Box textOverflow="ellipsis">{row.original.description}</Box>
      ),
    },
    {
      Header: "unit_price",
      accessor: "price_one",
      Cell: ({ row }) => {
        return <>{numberWithCommas(row.original.price_one * cost)}</>;
      },
    },
    {
      Header: "half_wholesale_price",
      accessor: "price_half_many",
      Cell: ({ row }) => {
        const [blur, setBlur] = React.useState(true);
        return (
          <Button onClick={() => setBlur(!blur)}>
            {blur ? "..." :numberWithCommas(row.original.price_half_many * cost)}
          </Button>
        );
      },
    },
    {
      Header: "wholesale_price",
      accessor: "price_many",
      Cell: ({ row }) => {
        const [blur, setBlur] = React.useState(true);
        return (
          <Button onClick={() => setBlur(!blur)}>
            {blur ? "..." : numberWithCommas(row.original.price_many * cost)}
          </Button>
        );
      },
    },
    {
      Header: "actions",
      Cell: ({ row }) => (
        <StyledLink to={`/products/${row.original.id}`}>
          <Iconify width={30} height={30} icon="ci:show" />
        </StyledLink>
      ),
    },
  ];

  return (
    <>
      <StyledContainer>
        <Header>
          <Title>
            <FormattedMessage id="products" />
          </Title>
          <Box sx={{ 
            display:'flex',
            gap:2
            }}
          >
            <Box width={200}>
              <Asynchronous
                name="categories"
                url="categories"
                optionLabel="name"
                control={control}
                errors={errors}
                label="categories"
                multiple={true}
                width={200}
              />
            </Box>
            <Button onClick={handlOpenProductModal}>
              <FormattedMessage id='print' />
            </Button>
          </Box>
          <StyledButton
            component={Link}
            variant="contained"
            color="primary"
            to="/create-invoice"
          >
            <FormattedMessage id="create_invoice" />
          </StyledButton>
        </Header>
        {isLoading && !data ? (
          <Spinner />
        ) : (
          <ReactTable data={data.data} columns={columns} meta={data.meta} />
        )}
      </StyledContainer>
      {
        <Modal open={openProductModal} handleClose={handlCloseProductModal}>
            <PrintProducts handleClose={handlCloseProductModal}/>
        </Modal>
      }
    </>
  );
};

export default Products;
