import { useTheme } from "@mui/material";
import React from "react";
import { IntlProvider } from "react-intl";
import { useRecoilValue } from "recoil";
import { localeState } from "../atoms/localeState";
import arMessages from "./messages/ar";
import enMessages from "./messages/en";

const allMessages = {
  en: enMessages,
  ar: arMessages,
};

export function I18nProvider({ children }) {
  const lang = useRecoilValue(localeState);
  const messages = allMessages[lang];

  const theme = useTheme();
  theme.direction = lang === "en" ? "ltr" : "rtl";
  document.dir = theme.direction;

  return (
    <IntlProvider locale={lang} messages={messages}>
      {children}
    </IntlProvider>
  );
}
