import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Alert, styled } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { AuthContext } from "../../auth/provider";
import CustomInput from "../../components/form/CustomInput/index";
import InputPassword from "../../components/form/InputPassword/index";

const LoginFormContianer = styled(Box)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  height: "100vh",
}));

const LoginFormBox = styled(Box)(({ theme }) => ({
  width: "33%",
  padding: "40px",
  boxShadow: "0px 2px 10px 4px rgb(0 0 0 / 20%)",
}));

const Login = () => {
  const [logging, setLogging] = useState(false);
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("vaild_email").required("email_is_required"),
    password: Yup.string().required("password_is_required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmitForm = (data) => {
    setLogging(true);
    let promise = user.login(data);
    promise
      .then(() => {
        setLogging(true);
        navigate("/");
      })
      .catch((err) => {
        setError(err?.response?.data?.error);
        setLogging(false);
      });
  };

  return (
    <LoginFormContianer>
      <LoginFormBox>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <Stack spacing={3}>
            {error ? <Alert severity="error">{error.message}</Alert> : null}
            <CustomInput
              label="email"
              name="email"
              control={control}
              errors={errors}
            />
            <InputPassword
              label="password"
              name="password"
              control={control}
              errors={errors}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          ></Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={logging}
          >
            <FormattedMessage id="login" />
          </LoadingButton>
        </form>
      </LoginFormBox>
    </LoginFormContianer>
  );
};

export default Login;
