import Cookies from "js-cookie";
import React from "react";
import { useAxios } from "../api";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const getUserCookie = () => {
    const user = Cookies.get("userToken");
    return user ? user : undefined;
  };
  const [user, setUser] = React.useState({
    token: getUserCookie(),
    user: {},
  });

  const axiosInstance = useAxios();

  const login = async ({ email, password }) => {
    let result = await axiosInstance
      .post("/auth/local", {
        identifier: email,
        password: password,
      })
      .then((data) => {
        Cookies.set("userToken", data.data.jwt);
        setUser({
          token: data.data.jwt,
          user: data.data.user,
        });
      });
    return result;
  };

  const logout = () => {
    Cookies.remove("userToken");
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        getUserCookie,
        login,
        logout,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
