import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useAxios } from "../../../api/index";
import { localeState } from "../../../atoms/localeState";
import CustomInput from "../../../components/form/CustomInput";
import Dropdown from "../../../components/form/Dropdown";
import Spinner from "../../../components/Spinner";

const OrderMakerForm = ({ handleClose, setValue }) => {
  const axiosInstance = useAxios();
  const queryClient = useQueryClient();
  const lang = useRecoilValue(localeState);

  const engineerSchema = Yup.object().shape({
    name: Yup.string().required("name_is_required"),
    phone: Yup.string().required("phone_is_required"),
    type: Yup.string().required("client_type_is_required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(engineerSchema),
    defaultValues: {
      name: "",
      phone: "",
      type: "",
    },
  });

  const { mutate, isLoading } = useMutation(
    (data) => axiosInstance.post("/client-users", data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("order_maker");
        setValue("order_maker", {
          ...data.data.data.attributes,
          id: data.data.data.id,
        });
        handleClose();
      },
    }
  );

  const onSubmitForm = (data) => {
    mutate({
      data: {
        ...data,
      },
    });
  };

  const options =
    lang === "ar"
      ? [
          {
            id: 1,
            name: "المفرد",
            value: "retailer",
          },
          {
            id: 2,
            name: "الجملة",
            value: "wholeseller",
          },
        ]
      : [
          {
            id: 1,
            name: "retailer",
            value: "retailer",
          },
          {
            id: 2,
            name: "wholeseller",
            value: "wholeseller",
          },
        ];

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={2}>
        <CustomInput
          name="name"
          label="name"
          control={control}
          errors={errors}
        />
        <CustomInput
          name="phone"
          label="phone"
          control={control}
          errors={errors}
        />
        <Dropdown
          name="type"
          label="client_type"
          options={options}
          optionLabel={"name"}
          control={control}
          errors={errors}
        />
        <Button type="submit">
          <FormattedMessage id="add" />
        </Button>
        <Button onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>
      </Stack>
    </Box>
  );
};

export default OrderMakerForm;
