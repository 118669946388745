import { Button, MenuList, Box, MenuItem, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useAxios } from "../../../api";
import useInfiniteScroll from "../../useInfiniteScroll";
import { styled } from "@mui/material/styles";
import Spinner from "../../Spinner";

const StyledMenuItem = styled(MenuItem)(() => ({
  width: "100%",
}));

export default function Asynchronous({
  url,
  name,
  control,
  errors,
  optionLabel,
  label = "Autocomplete",
  handleNoOption,
  getLabel,
  multiple = false,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchQuarry, setSearchQuarry] = useState(undefined);
  const loading = open && options.length === 0;
  const axiosInstance = useAxios();
  const [page, setPage] = React.useState(1);

  const {
    isLoading: paginateLoading,
    hasMore,
    options: products,
    isFetching,
  } = useInfiniteScroll(page, url, getLabel, getLabel);

  const observer = React.useRef();
  const lastElementRef = React.useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [paginateLoading, hasMore]
  );

  const { refetch, isLoading, isRefetching } = useQuery(
    name,
    () =>
      axiosInstance
        .get(url, {
          params: {
            filters: {
              name: {
                $contains: searchQuarry,
              },
            },
          },
        })
        .then((data) => {
          let options = data.data.data.map((item) => ({
            ...item,
            name: getLabel
              ? getLabel(item.attributes)
              : item.attributes[optionLabel],
          }));
          setOptions([...options]);
        }),
    {
      enabled: false,
    }
  );

  React.useEffect(() => {
    setOptions(products);
  }, [products]);

  React.useEffect(() => {
    const isFetching = isLoading || isRefetching;
    if (open && !isFetching) {
      refetch();
    }
  }, [searchQuarry, open]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      if (active) {
        refetch();
      }
    })();
    return () => {
      active = false;
    };
  }, [isLoading]);

  const inputRender = (params = {}, onChange, error) => {
    const onInputChange = (event) => {
      event.preventDefault();
      setSearchQuarry(event.target.value);
    };
    return (
      <TextField
        {...params}
        label={<FormattedMessage id={label} />}
        error={Boolean(errors[name] && errors[name])}
        helperText={error && <FormattedMessage id={error.message} />}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading && isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : loading && !isLoading ? (
                handleNoOption ? (
                  <Button onClick={handleNoOption}>
                    <FormattedMessage id="add" />
                  </Button>
                ) : (
                  <FormattedMessage id="no_options_found" />
                )
              ) : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        onChange={onInputChange}
      />
    );
  };

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    // if (isFetching) return <Spinner />;
    const { children } = props;
    return (
      <Grid container {...props}>
        <Grid container item xs={12} ref={ref}>
          {children &&
            children.map((element, index) => {
              if (index + 1 == children.length) {
                return (
                  <Grid
                    item
                    xs={12}
                    key={element.props.id}
                    {...element.props}
                    ref={lastElementRef}
                  >
                    {element.key}
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} key={element.props.id} {...element.props}>
                  {element.key}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            multiple={multiple}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              option[optionLabel] ?? option.name ? option.name : ""
            }
            options={options}
            loading={isLoading}
            value={value}
            onChange={(event, values, reason) => {
              onChange(values);
            }}
            onBlur={onBlur}
            inputRef={ref}
            renderInput={(params) => inputRender(params, onChange, error)}
            ListboxComponent={ListboxComponent}
            noOptionsText={
              handleNoOption ? (
                <Button onClick={handleNoOption}>
                  <FormattedMessage id="add" />
                </Button>
              ) : (
                <FormattedMessage id="no_options_found" />
              )
            }
          />
        );
      }}
    />
  );
}
