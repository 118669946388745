import { Button, Container, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useAxios } from "../../api";
import ReactTable from "../../components/ReactTable";
import Spinner from "../../components/Spinner";
import { columns } from "./columns";
import { useSearchParams } from "react-router-dom";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  gap: "20px",
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "30px",
  letterSpacing: "-0.1rem",
  color: theme.palette.primary.main,
}));

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "600",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Invoice = () => {
  const axiosInstance = useAxios();
  let [searchParams, setSearchParams] = useSearchParams();

  const fetchedData = {
    page: searchParams.get("page") ?? undefined,
  };

  const { data, isLoading } = useQuery(["invoices", fetchedData], () =>
    axiosInstance
      .get("/invoice/user", {
        params: {
          populate: "*",
          pagination: {
            page: fetchedData.page,
          },
          sort: {
            id: "desc",
          },
        },
      })
      .then((data) => {
        return {
          data: data.data.data.map((d) => ({ ...d.attributes, id: d.id })),
          meta: data.data.meta.pagination,
        };
      })
  );

  if (isLoading && !data) {
    return <Spinner />;
  }

  return (
    <>
      <StyledContainer>
        <Header>
          <Title>
            <FormattedMessage id="my_invoices" />
          </Title>
          <StyledButton
            component={Link}
            variant="contained"
            color="primary"
            to="/create-invoice"
          >
            <FormattedMessage id="create_invoice" />
          </StyledButton>
        </Header>
        {data && (
          <ReactTable data={data.data} columns={columns} meta={data.meta} />
        )}
      </StyledContainer>
    </>
  );
};

export default Invoice;
