import { styled } from "@mui/material";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import Iconify from "../../components/Iconify";

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: "inherite",
  color: theme.palette.primary.main,
  fontWeight: "600",
  whiteSpace: "nowrap",
  textDecoration: "none",
  fontSize: "14px",
  textTransform: "uppercase",
  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: "inherit",
  },
  transition: "300ms",
  padding: "5px",
}));

export const columns = [
  {
    Header: "order_maker",
    accessor: "client_user.data.attributes.name",
  },
  {
    Header: "engineer",
    accessor: "engineer.data.attributes.name",
    Cell: ({ row }) => (
      <>
        {row.original.engineer.data.attributes.first_name +
          " " +
          row.original.engineer.data.attributes.last_name}
      </>
    ),
  },
  {
    Header: "description",
    accessor: "description",
  },
  {
    Header: "created_at",
    accessor: "createdAt",
    Cell: ({ row }) => (
      <>{format(parseISO(row.original.createdAt), "dd/MM/yyyy")}</>
    ),
  },
  {
    Header: "actions",
    Cell: ({ row }) => (
      <StyledLink to={`/my-invoices/${row.original.id}`}>
        <Iconify width={30} height={30} icon="ci:show" />
      </StyledLink>
    ),
  },
];
