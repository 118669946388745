import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { localeState } from "../atoms/localeState";
import { AuthContext } from "../auth/provider";
import logo from "../logo.jpg";

const NavBackground = styled(Box)(({ theme }) => ({
  boxShadow: "0 8px 6px -6px rgb(0 0 0 / 20%)",
  backgroundColor: theme.palette.background.default,
  width: "100%",
  boxSizing: "border-box",
  height: "80px",
  marginBottom: "25px",
}));

const Logo = styled(Box)({
  width: "60px",
  mixBlendMode: "multiply",
});

const NavLinks = styled(Box)({
  display: "flex",
  gap: "25px",
});

const NavLink = styled(Link)(({ theme }) => ({
  backgroundColor: "inherite",
  color: theme.palette.primary.main,
  fontWeight: "700",
  whiteSpace: "nowrap",
  textDecoration: "none",
  fontSize: "14px",
  textTransform: "uppercase",
  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: "inherit",
  },
  transition: "300ms",
  padding: "5px",
}));

const UserSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const StyledContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  height: "100%",
  justifyContent: "space-between",
});

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "600",
  border: "1px solid",
  borderColor: theme.palette.secondary.main,
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  transition: "300ms",
}));

const Navbar = () => {
  const [lang, setLang] = useRecoilState(localeState);

  const user = useContext(AuthContext);

  const handleLanguageChange = (event) => {
    Cookies.set("lang", event.target.value);
    setLang(event.target.value);
  };
  return (
    <>
      <NavBackground>
        <StyledContainer>
          <NavLink to="/">
            <Logo component="img" src={logo} />
          </NavLink>
          <NavLinks>
            <NavLink to="/">
              <FormattedMessage id="products" />
            </NavLink>
            {/* <NavLink to="..">
              <FormattedMessage id="categories" />
            </NavLink> */}
            <NavLink to="/my-invoices">
              <FormattedMessage id="my_invoices" />
            </NavLink>
          </NavLinks>
          <UserSection>
            <Select
              value={lang}
              onChange={handleLanguageChange}
              sx={{ height: "36px" }}
            >
              <MenuItem value="en">
                <FormattedMessage id="english" />
              </MenuItem>
              <MenuItem value="ar">
                <FormattedMessage id="arabic" />
              </MenuItem>
            </Select>
            <StyledButton variant="outlined" onClick={() => user.logout()}>
              <FormattedMessage id="logout" />
            </StyledButton>
          </UserSection>
        </StyledContainer>
      </NavBackground>
    </>
  );
};

export default Navbar;
