import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Checkbox, Container, FormControlLabel, styled, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useAxios } from '../../../api';
import Asynchronous from '../../../components/form/Async/index';
import CustomInput from '../../../components/form/CustomInput';
import Iconify from '../../../components/Iconify';
import Modal from '../../../components/Modal/index';
import ReactTable from '../../../components/ReactTable';
import EngineerFrom from '../Engineer/Form';
import ProductForm from '../Product/Form';
import CustomTextarea from '../../../components/form/CustomTextarea/index';
import { AuthContext } from '../../../auth/provider';
import Dropdown from '../../../components/form/Dropdown/index';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../atoms/localeState';
import OrderMakerForm from '../OrderMaker/Form';
import { numberWithCommas } from '../../../functions';

const EndFlex = styled(Box)({
 display: 'flex',
 alignItems: 'center',
 justifyContent: 'flex-end',
 gap: '10px',
});

const ColumnFlex = styled(Box)({
 display: 'flex',
 flexDirection: 'column',
 gap: '10px',
});

const NotesContainer = styled(Stack)(({ theme }) => ({
 border: '1px solid',
 borderColor: theme.palette.primary.main,
 padding: '2rem',
 borderRadius: '10px',
}));

const ActionsContainer = styled(Stack)({
 flexDirection: 'row',
 justifyContent: 'flex-end',
 marginTop: '1rem',
 gap: '10px',
});

const StyledButton = styled(Button)(({ theme }) => ({
 fontWeight: '600',
 '&:hover': {
  backgroundColor: theme.palette.secondary.main,
 },
}));

const CreateInvoice = () => {
 const [notes, setNotes] = React.useState([]);
 const navigate = useNavigate();
 const axiosInstance = useAxios();
 const user = React.useContext(AuthContext);
 const lang = useRecoilValue(localeState);

 const [totalPrice, setTotalPrice] = React.useState(0);
 const InvoiceForm = Yup.object().shape({
  engineer: Yup.object().required().typeError('engineer_is_required'),
  order_maker: Yup.object().nullable().typeError('order_maker_is_required'),
  products: Yup.array().min(1, 'products_1').required('products_is_required'),
  notes: Yup.array().min(1, 'notes_1').required(),
  invoice_type: Yup.string().required('invoice_type_is_required'),
  description: Yup.string().nullable(),
  type_group: Yup.string().required('type_group_is_required'),
  discount: Yup.number().nullable().typeError('discount_number'),
 });

 const [openProductModal, setOpenProductModal] = React.useState(false);
 const handlCloseProductModal = () => setOpenProductModal(false);
 const handlOpenProductModal = () => setOpenProductModal(true);

 const [openEngineerModal, setOpenEngineerModal] = React.useState(false);
 const handlCloseEngineerModal = () => setOpenEngineerModal(false);
 const handlOpenEngineerModal = () => setOpenEngineerModal(true);

 const [openOrderMakerModal, setOpenOrderMakerModal] = React.useState(false);
 const handlCloseOrderMakerModal = () => setOpenOrderMakerModal(false);
 const handlOpenOrderMakerModal = () => setOpenOrderMakerModal(true);

 const {
  control,
  handleSubmit,
  setValue,
  getValues,
  watch,
  formState: { errors },
 } = useForm({
  resolver: yupResolver(InvoiceForm),
  defaultValues: {
   engineer: '',
   order_maker: '',
   products: [],
   notes: [],
   invoice_type: '',
   description: '',
   type_group: '',
   discount: '',
  },
 });

 const productsField = watch('products');

 useQuery('notes', () =>
  axiosInstance.get('/notes').then((data) => {
   let notes = data.data.data.map((note) => ({ ...note, checked: 0 }));
   setNotes(notes);
  })
 );

 const { data: cost } = useQuery('cost', () =>
  axiosInstance.get('/cost').then((data) => {
   return data.data.data.attributes.variableCost;
  })
 );

 const handleCheckChange = (e) => {
  setNotes((prev) => {
   return prev.map((note) => {
    if (note.id == e.target.name) {
     return { ...note, checked: e.target.checked };
    }
    return note;
   });
  });
  setValue('notes', notes);
 };

 const onSubmitForm = ({ engineer, products, order_maker, type_group, description, invoice_type, discount }) => {
  let notesIds = notes.filter((note) => note.checked);
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${user?.user?.token && user?.user?.token}`);
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify({
   data: {
    client_user: order_maker?.id ?? '',
    engineer: engineer.id,
    order: products.map((product) => {
     return {
      id: product.product.id,
      name: product?.product?.attributes?.name,
      unit_price: product.custom_price
       ? product.custom_price
       : watch('invoice_type') === 'retailer'
       ? product.product.attributes.price_one * cost
       : watch('invoice_type') === 'wholeseller'
       ? product.product.attributes.price_many * cost
       : product.product.attributes.price_half_many * cost,
      quantity: product.amount,
     };
    }),
    notes: notesIds ? notesIds.map((note) => note.id) : null,
    description: description,
    invoice_type: invoice_type,
    type_group: type_group,
    discount: discount,
   },
  });

  var requestOptions = {
   method: 'POST',
   headers: myHeaders,
   body: raw,
   redirect: 'follow',
  };

  fetch(`${process.env.REACT_APP_BACKEND_SERVER}/invoices?populate=*`, requestOptions).then(navigate('/my-invoices'));
 };

 React.useEffect(() => {
  setTotalPrice(() => {
   let products = productsField ?? [];
   if (products.length > 0) {
    let sum = products
     .map((product) => {
      return product.custom_price
       ? product.custom_price * product.amount
       : watch('invoice_type') === 'retailer'
       ? product.product.attributes.price_one * cost * product.amount
       : watch('invoice_type') === 'wholeseller'
       ? product.product.attributes.price_many * cost * product.amount
       : product.product.attributes.price_half_many * cost * product.amount;
     })
     .reduce((a, b) => a + b);
    return sum;
   }
   return 0;
  });
 }, [productsField, watch('invoice_type')]);

 const columns = [
  {
   Header: 'product_name',
   accessor: 'product.name',
  },
  {
   Header: 'category',
   accessor: 'product.attributes.categories.data[0].attributes.name',
  },
  {
   Header: 'brand',
   accessor: 'product.attributes.brand.data.attributes.name',
  },
  {
   Header: 'amount',
   accessor: 'amount',
  },
  {
   Header: 'one_price',
   Cell: ({ row }) => {
    let price = row.original.custom_price
     ? row.original.custom_price
     : watch('invoice_type') === 'retailer'
     ? row.original.product.attributes.price_one * cost
     : watch('invoice_type') === 'wholeseller'
     ? row.original.product.attributes.price_many * cost
     : row.original.product.attributes.price_half_many * cost;
    return <>{numberWithCommas(price)}</>;
   },
  },
  {
   Header: 'price',
   Cell: ({ row }) => {
    let price = row.original.custom_price
     ? row.original.custom_price * row.original.amount
     : watch('invoice_type') === 'retailer'
     ? row.original.product.attributes.price_one * cost * row.original.amount
     : watch('invoice_type') === 'wholeseller'
     ? row.original.product.attributes.price_many * cost * row.original.amount
     : row.original.product.attributes.price_half_many * cost * row.original.amount;
    return <>{numberWithCommas(price)}</>;
   },
  },
  {
   Header: 'actions',
   Cell: ({ row }) => {
    return (
     <Button
      key={row.id}
      onClick={() => {
       let products = watch('products');
       products.splice(row.index, 1);
       setValue('products', products);
      }}
     >
      <FormattedMessage id="delete" />
     </Button>
    );
   },
  },
 ];

 const options =
  lang === 'ar'
   ? [
      {
       id: 1,
       name: 'المفرد',
       value: 'retailer',
      },
      {
       id: 2,
       name: 'نصف الجملة',
       value: 'semi_wholeseller',
      },
      {
       id: 3,
       name: 'الجملة',
       value: 'wholeseller',
      },
     ]
   : [
      {
       id: 1,
       name: 'retailer',
       value: 'retailer',
      },
      {
       id: 2,
       name: 'semi wholeseller',
       value: 'semi_wholeseller',
      },
      {
       id: 3,
       name: 'wholeseller',
       value: 'wholeseller',
      },
     ];

 return (
  <>
   <Container>
    <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
     <Stack display="flex" spacing={4}>
      <Stack flexDirection="row" alignItems="flex-start" gap={2}>
       <Stack spacing={1.5} flexBasis="35%">
        <Asynchronous
         name="engineer"
         url="engineers"
         optionLabel="first_name"
         control={control}
         errors={errors}
         label="engineer"
         handleNoOption={() => {
          handlOpenEngineerModal();
         }}
         getLabel={(engineer) => {
          return engineer.first_name + ' ' + engineer.last_name;
         }}
        />

        <Asynchronous
         name="order_maker"
         url="client-users"
         optionLabel="name"
         control={control}
         errors={errors}
         label="order_maker"
         handleNoOption={() => {
          handlOpenOrderMakerModal();
         }}
        />
       </Stack>
       <Stack spacing={1.5} flexBasis="35%">
        <CustomInput name="type_group" label="type_group" control={control} errors={errors} />
        <Dropdown
         name="invoice_type"
         label="invoice_type"
         options={options}
         optionLabel={'name'}
         control={control}
         errors={errors}
        />
       </Stack>

       <CustomTextarea label="description" name="description" control={control} errors={errors} />
      </Stack>

      <ColumnFlex>
       <EndFlex>
        <StyledButton
         startIcon={<Iconify icon="material-symbols:add" />}
         variant="contained"
         onClick={handlOpenProductModal}
         disabled={!watch('invoice_type')}
        >
         <FormattedMessage id="add_product" />
        </StyledButton>
       </EndFlex>
       {errors.products && (
        <Alert severity="error">
         <FormattedMessage id={errors.products.message} />
        </Alert>
       )}
       <ReactTable data={getValues('products') ?? []} columns={columns} />
       {getValues('products')?.length > 0 && (
        <>
         <EndFlex marginRight={2}>
          <Box sx={{ width: '25%' }}>
           <CustomInput name="discount" label="discount" control={control} errors={errors} />
          </Box>
          <Typography fontWeight="600">
           <FormattedMessage id="total_price" />
          </Typography>
          {numberWithCommas(totalPrice)}
         </EndFlex>

         {watch('discount') && (
          <EndFlex marginRight={2}>
           <Typography fontWeight="600">
            <FormattedMessage id="total_price_after_discount" />
           </Typography>
           {numberWithCommas(totalPrice - (watch('discount') ?? 0))}
          </EndFlex>
         )}
        </>
       )}
      </ColumnFlex>
      <Stack sx={{}} spacing={0.6}>
       {errors.notes && (
        <Alert severity="error">
         <FormattedMessage id={errors.notes.message} />
        </Alert>
       )}
       <NotesContainer>
        {notes.map((note) => {
         let { id, attributes, checked } = note;
         return (
          <FormControlLabel
           key={id}
           name={id}
           control={<Checkbox onChange={handleCheckChange} checked={checked} />}
           label={attributes?.nots}
          />
         );
        })}
       </NotesContainer>
      </Stack>
     </Stack>
     <ActionsContainer>
      <StyledButton variant="contained" type="submit">
       <FormattedMessage id="save" />
      </StyledButton>
     </ActionsContainer>
    </Box>
   </Container>
   {
    <Modal open={openProductModal} handleClose={handlCloseProductModal}>
     <ProductForm setValue={setValue} getValues={getValues} handleClose={handlCloseProductModal} />
    </Modal>
   }
   {
    <Modal open={openOrderMakerModal} handleClose={handlCloseOrderMakerModal}>
     <OrderMakerForm setValue={setValue} getValues={getValues} handleClose={handlCloseOrderMakerModal} />
    </Modal>
   }
   {
    <Modal open={openEngineerModal} handleClose={handlCloseEngineerModal}>
     <EngineerFrom handleClose={handlCloseEngineerModal} />
    </Modal>
   }
  </>
 );
};

export default CreateInvoice;
