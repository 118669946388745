import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useAxios } from "../api";

const useInfiniteScroll = (page, url, setElements, getLabel, optionLabel) => {
  const [options, setOptions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const axiosInstance = useAxios();

  const { isLoading, isFetching, isError, refetch } = useQuery(
    [url],
    () =>
      axiosInstance(url, {
        params: {
          pagination: {
            page: page,
          },
        },
      }).then((data) => {
        setHasMore(data?.data.meta.pagination?.pageCount - page > 0);
        setOptions((prev) => {
          let options = data.data.data.map((item) => ({
            ...item,
            name: getLabel
              ? getLabel(item.attributes)
              : item.attributes[optionLabel],
          }));
          return [...prev, ...options];
        });
      }),
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  return { isLoading, isError, hasMore, options, isFetching };
};

export default useInfiniteScroll;
