import React from "react";
import { ClickAwayListener, Modal, Box } from "@mui/material";
const ImageModal = ({ row }) => {
  const [preview, setPreview] = React.useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "transparent",
    maxHeight: "80%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#707070",
      borderRadius: "6px",
      padding: "2px",
      visibility: "hidden",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        visibility: "visible",
      },
    },
    textAlign: "center",
  };

  const handleClose = () => {
    setPreview(false);
  };

  return (
    <>
      <Box
        onClick={() => setPreview(!preview)}
        component="img"
        src={
          row.original.image?.data
            ? `${process.env.REACT_APP_BACKEND_SERVER_upload}${row.original.image?.data?.attributes.url}`
            : ""
        }
        width="50px"
        sx={{ mixBlendMode: "multiply" }}
      />
      {preview && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Modal
              open={preview}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ border: "none", "& > .MuiBox-root": { outline: "unset" } }}
            >
              <Box sx={style}>
                <img
                  src={
                    row.original.image?.data
                      ? `${process.env.REACT_APP_BACKEND_SERVER_upload}${row.original.image?.data?.attributes.url}`
                      : ""
                  }
                  style={{
                    "object-fit": "contain",
                    width: "80%",
                    heigth: "80%",
                  }}
                />
              </Box>
            </Modal>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default ImageModal;
