import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useAxios } from "../../../api/index";
import CustomInput from "../../../components/form/CustomInput";
import Spinner from "../../../components/Spinner";

const EngineerFrom = ({ handleClose }) => {
  const axiosInstance = useAxios();
  const queryClient = useQueryClient();

  const engineerSchema = Yup.object().shape({
    first_name: Yup.string().required("first_name_is_required"),
    last_name: Yup.string().required("last_name_is_required"),
    phone: Yup.string().required("phone_is_required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(engineerSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
    },
  });

  const { mutate, isLoading } = useMutation(
    (data) => axiosInstance.post("/engineers", data),
    {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries("engineer");
      },
    }
  );

  const onSubmitForm = (data) => {
    mutate({
      data: {
        ...data,
        name: data.first_name,
      },
    });
  };

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={2}>
        <CustomInput
          name="first_name"
          label="first_name"
          control={control}
          errors={errors}
        />
        <CustomInput
          name="last_name"
          label="last_name"
          control={control}
          errors={errors}
        />
        <CustomInput
          name="phone"
          label="phone"
          control={control}
          errors={errors}
        />
        <Button type="submit">
          <FormattedMessage id="add" />
        </Button>
        <Button onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>
      </Stack>
    </form>
  );
};

export default EngineerFrom;
